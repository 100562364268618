<template>
  <div class="container-top" v-if="product">
    <div class="pc container padding-top-60 position-relative">
      <div class="h5 main" style="padding-bottom:52px">결제하기</div>
      <div class="body0-bold main account-price-title">결제금액</div>
      <template>
        <div class="item">
          <div class="subtitle5 sub2">상품</div>
          <div>
            <div class="flex-end item-product">
              <div class="body2 sub3">{{ product.name }}</div>
              <span class="price-indicator"></span>
              <div class="price body2-bold main">
                {{ product.price.price |currencyNum }}</div>
              <span class="body4 sub">원</span>
            </div>
          </div>
        </div>
      </template>

      <!-- 총 결제금액 -->
      <div style="margin-top:26px">
        <div class="flex-between">
          <div class="subtitle4 main">총 결제금액</div>
          <div>
            <span class="price h5 main">{{ product.price.price|currencyNum }}</span><span class="body2-medium sub">원</span>
          </div>
        </div>
      </div>

      <!-- 결제 방법 -->
      <div style="margin-top:92px">
        <div class="account-price-method">
          <h5 class="body0-bold main">결제 방법</h5>
          <!--<div class="sub3" style="margin-top:8px">비용을 납부할 카드를 선택 해주세요.</div>-->
          <button class="button is-primary" style="margin-top:20px;width:120px">신용카드</button>
        </div>
        <!--<div style="margin:32px 0 56px 0">
          <billing-form ref="form"></billing-form>
        </div>-->
      </div>
      <div class="flex-center" style="margin-top:80px">
        <button class="button is-primary" style="width:242px;margin-left:8px" @click="clickAccount">결제하기</button>
      </div>
    </div>
    <div class="mobile container padding-top-24 position-relative">
      <div class="h6 main" style="padding-bottom:28px">결제하기</div>
      <div class="body2-bold main account-price-title">결제금액</div>
      <template>
        <div class="item">
          <div class="body4-bold sub2">상품</div>
          <div>
            <div class="flex-end item-product">
              <div class="body4 sub3">{{ product.name }}</div>
              <span class="price-indicator"></span>
              <div class="price body4-bold main">
                {{ product.price.price |currencyNum }}</div>
              <span class="body4 sub">원</span>
            </div>
          </div>
        </div>
      </template>

      <!-- 총 결제금액 -->
      <div style="margin-top:26px">
        <div class="flex-between">
          <div class="subtitle6 main">총 결제금액</div>
          <div>
            <span class="price body0-bold main">{{ product.price.price|currencyNum }}</span><span class="body2-medium sub">원</span>
          </div>
        </div>
      </div>

      <!-- 결제 방법 -->
      <div style="margin-top:92px">
        <div class="account-price-method">
          <h5 class="body2-bold main">결제 방법</h5>
          <!--<div class="sub3" style="margin-top:8px">비용을 납부할 카드를 선택 해주세요.</div>-->
          <button class="button is-primary" style="margin-top:20px;width:120px">신용카드</button>
        </div>
        <!--<div style="margin:32px 0 56px 0">
          <billing-form ref="form"></billing-form>
        </div>-->
      </div>
      <div class="flex-center" style="margin-top:40px">
        <button class="button is-primary body4-bold" style="width:200px;height:48px;margin-left:8px" @click="clickAccount">결제하기</button>
      </div>
    </div>
  </div>
</template>

<script>
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  export default {
    name: "ServiceAccount",
    mixins: [
      UserAPIMixin,
    ],
    created() {
      this.init();
    },
    data() {
      return {
        service: undefined,
        product: undefined,
        visiblePG: false
      }
    },
    methods: {
      init() {
        this.$axios.get(`public/launchpack/service/${this.$route.query.service_id}`).then(res => {
          this.service = res.data;
        });
        this.request.user.get(`mapping/product/${this.$route.query.id}?fields=id,name,price,category1,category2`).then(res => {
          this.product = res.data;
        });
      },
      validate() {
        let valid = true;
        Object.keys(this.$refs.form.value).forEach(key=>{
          if(this.$refs.form.value[key] === '') {
            valid = false;
          }
        });

        return valid;
      },
      account(user_bill_id) {
        let params = {
          user_bill: user_bill_id,
          product_id: this.product.id
        }
        this.request.user.post(`launchpack/service/${this.$route.query.service_id}/simple_account`, params).then(()=>{
          this.routerPush('service_account_simple_done');
        });
      },
      // 일반 결제
      async clickAccount() {
        let res = await this.$axios.get('public/payple/auth');
        let auth = res.data;
        res = await this.$axios.post('public/merchant', {
          price: this.product.price.price,
          // 서비스별 이용내역에 기록할 내용
          payinfo: {
            service_id: this.service.id,
            billing_date: this.dayjs().format('YYYY-MM-DD HH:mm:ss'),
            billing_status: 1,
            category_id: this.product.category2,
            product_id: this.product.id,
            product_price: this.product.price.price,
            pay_method: 4
          }
        }); 
        let mid = res.data;
        let obj = {
          PCD_PAY_TYPE: 'card',
          PCD_PAY_WORK: 'PAY',
          PCD_CARD_VER: '02',
          PCD_PAYER_NO: this.service.id,
          PCD_PAYER_NAME : this.service.service_user.name,
          PCD_PAYER_HP : this.service.service_user.phone,
          PCD_PAYER_EMAIL : this.service.service_user.email,
          PCD_PAY_GOODS: this.product.name,
          PCD_PAY_TOTAL: this.product.price.price,
          PCD_PAY_ISTAX: 'Y',
          PCD_PAY_TAXTOTAL: Math.round(this.product.price.price * (10/11)),
          PCD_AUTH_KEY: auth.AuthKey,
          PCD_PAY_URL: auth.return_url,
          PCD_PAY_OID: mid,
          PCD_RST_URL: `${location.origin}/api/public/simple_account`
          // test
          //PCD_RST_URL: `http://localhost:8001/public/simple_account`
        }
        /*global PaypleCpayAuthCheck, */
        /*eslint no-undef: "error"*/
        PaypleCpayAuthCheck(obj);
      },
      // 빌링 결제
      clickAccountBilling() {

          if(this.$refs.form.selectedBill) {
            this.account(this.$refs.form.selectedBill.id);
          } else {
            if(!this.validate()) {
              this.toast('카드 정보를 제대로 입력해주세요.');
              return;
            }

            this.setLoading('카드 등록중입니다');
            this.request.user.post('launchpack/card_bill', this.$refs.form.value).then(res=>{
              this.clearLoading();
              if(res.data.result === 'error') {
                if(res.data.message.indexOf('CDAU1002')>-1) {
                  this.toast(res.data.message.replace('[CDAU1002] ',''));
                } else {
                  this.toast('카드 정보가 잘못 입력되었습니다.');
                  Object.keys(this.$refs.form.err).forEach((key=>{
                    this.$refs.form.err[key].err = false;
                  }));
                  for(let item of [
                    { code: '3110', key: 'card_num' },
                    { code: '3115', key: 'pw' },
                    { code: '3119', key: 'ex' },
                    { code: '3131', key: 'birth' }
                  ]) {
                    if(res.data.message.indexOf(item.code)>-1) {
                      this.$refs.form.err[item.key].err = true;
                    }
                  }
                }
              } else {
                // 카드 등록 성공
                this.account(res.data.bill_id);
              }
            }).catch(()=>{
              this.clearLoading();
            });
          }
        }
    },
    computed: {
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .account-price-title
    border-top 1px solid $main
    border-bottom 1px solid $sub5
    padding 16px 0
  .account-price-method
    border-top 1px solid $main
    border-bottom 1px solid $gray2
    padding 16px 0
  .item
    padding 32px 0
    border-bottom 1px solid $gray2
    display flex
    justify-content space-between
  .item-product
    margin-bottom 8px
    text-align right
  .item-product:last-child
    margin-bottom 0
  .box-info
    background-color $gray4
    padding 24px
    border-radius 8px
    margin-top 8px

  .box-method
    border 1px solid $primary
    background-color $primary-light2
    color $primary
    text-align center
    width 130px
    height 40px
    line-height 40px
    border-radius 8px
    font-size 14px

  .price-indicator
    border 2px solid $gray1
    border-radius 50%
    margin 0 12px
</style>
